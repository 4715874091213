import React from "react";
import NavBar from "./components/NavBar";
import TopBar from "./components/TopBar";

const Header = () => {
  return (
    <header className="site-header">
      <div className="header-contents">
        <TopBar />
        <NavBar />
      </div>
    </header>
  );
};

export default Header;
