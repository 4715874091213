import React from "react";
import map from "../../assets/images/icons/map.svg";
import phone from "../../assets/images/icons/phone.svg";
import email from "../../assets/images/icons/email.svg";
import fb from "../../assets/images/icons/facebook.png";
import twi from "../../assets/images/icons/twitter.png";
import wa from "../../assets/images/icons/whatsapp.png";

const ContactBody = () => {
  return (
    <section className="section section-spacing-100">
      <div className="container">
        <div className="large">
          <div className="section-content width-960 margin-auto">
            <div className="contacts-container flex">
              <div className="contact-item">
                <div className="contact-item-head flex">
                  <img src={map} alt="Map" /> <h2>Address</h2>
                </div>
                <div className="contact-item-body">
                  <p>Kirolo Matugga, Semuto Road</p>
                  <p>Kampala, Uganda</p>
                </div>
              </div>
              <div className="contact-item">
                <div className="contact-item-head flex">
                  <img src={email} alt="Email" /> <h2>Email</h2>
                </div>
                <div className="contact-item-body">
                  <p>info@ghsfa.org</p>
                  <p>support@ghsfa.org</p>
                </div>
              </div>
              <div className="contact-item">
                <div className="contact-item-head flex">
                  <img src={phone} alt="Phone" /> <h2>Phone Numbers</h2>
                </div>
                <div className="contact-item-body">
                  <p>+256 (756) 610 041</p>
                  <p>+256 (783) 352 546</p>
                </div>
              </div>
            </div>
            <div className="map-container width-100 section-spacing-50">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d127669.34119192552!2d32.472394!3d0.4991568!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x177daccd07e19987%3A0xda166dce838ff6ca!2sGreat%20Habib%20Smart%20Farm%20Academy!5e0!3m2!1sen!2sug!4v1701491118458!5m2!1sen!2sug"
                style={{ border: 0 }}
                allowfullscreen="true"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className="address-message flex justify-center section-spacing-30">
              <a
                href="https://form.jotform.com/greathabibfarmacademy/write-to-us"
                className="button green-bg white boldTitle"
              >
                Write To Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactBody;
