import React from "react";
import t1 from "../../assets/images/team/t1.jpg";
import t2 from "../../assets/images/team/t2.jpg";
import t3 from "../../assets/images/team/t3.jpg";
import t4 from "../../assets/images/team/okello.jpeg";
import t5 from "../../assets/images/team/mig.jpg";
import t6 from "../../assets/images/team/bongo.jpg";
import TeamCard from "../reusables/TeamCard";

const TeamBody = () => {
  return (
    <section className="sections section-spacing-50 farm-bg">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <div className="section-body border-radius-2">
                <ul className="team-container">
                  <TeamCard pic={t1} name="Shubi Nantege" title="Director" />
                  <TeamCard
                    pic={t2}
                    name="Nyakana Robert Nasser"
                    title="Ag. Production"
                  />
                  <TeamCard
                    pic={t3}
                    name="Namara Morren"
                    title="Farm Manager"
                  />
                  <TeamCard
                    pic={t4}
                    name="Okello Derrick"
                    title="Logistics Officer"
                  />
                  <TeamCard
                    pic={t5}
                    name="Migadde Habib"
                    title="Assistant Production Manager"
                  />
                  <TeamCard
                    pic={t6}
                    name="Bongoman Bongo Sadat"
                    title="Marketing Executive"
                  />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamBody;
