import React, { useState } from "react";
import logo from "../../../assets/images/logo/logo.png";
import bars from "../../../assets/images/icons/bars.svg";
import close from "../../../assets/images/icons/close.svg";

const NavBar = () => {
  const [showParent, setShowParent] = useState(false);
  return (
    <section className="navbar">
      <div className="container">
        <div className="large">
          <div className="nav-contents flex">
            <a className="brand-side" href="/">
              <img src={logo} alt="Great Habib Farm Academy logo" />
            </a>
            <nav className="menu-side relative">
              <label
                onClick={() => setShowParent(true)}
                htmlFor="toggle-responsive-menu"
                className="nav-label bold-title"
              >
                <img src={bars} alt="Menu" />
              </label>
              <input type="checkbox" id="toggle-responsive-menu" />
              <div
                onClose={() => setShowParent(false)}
                showParent={showParent}
                className={`menu-side-inner ${showParent ? "showParent" : ""}`}
              >
                <div className="hide-menu">
                  <button
                    onClick={() => setShowParent(false)}
                    className="hide-menu-btn button"
                  >
                    <img src={close} alt="Close Menu" />
                  </button>
                </div>
                <ul className="main-menu">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/about-us">About Us</a>
                    {/*
                  <li className="dropdown" id="about">
                    <a className="dropdownTrigger">
                      About Us<i className="arrow down"></i>
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <a href="/about-us">About GHSFA</a>
                      </li>
                      <li>
                        <a href="#">Our Team</a>
                      </li>
                      <li>
                        <a href="#">Our Model</a>
                      </li>
                    </ul>
                  */}
                  </li>
                  <li>
                    <a href="/initiatives">What We do</a>
                  </li>
                  {/*
                  <li className="dropdown" id="wedo">
                    <a className="dropdownTrigger">
                      What We do<i className="arrow down"></i>
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <a href="/services">Our Services</a>
                      </li>
                      <li>
                        <a href="#">Partnerships</a>
                      </li>
                      <li>
                        <a href="/initiatives">Key Initiatives</a>
                      </li>
                    </ul>
                  </li>
                  */}
                  <li>
                    <a href="/academy">Farm Tour</a>
                  </li>
                  <li>
                    <a href="/contact-us">Contact Us</a>
                  </li>
                  <li className="hide-responsive">
                    <a href="/team">Our Team</a>
                  </li>
                  <li className="hide-responsive">
                    <a href="/gallery">Farm Gallery</a>
                  </li>
                </ul>
                <a href="/Shop" className="button nav-cta boldTitle uppercase">
                  Farm Shop
                </a>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NavBar;
