import React from "react";
import { Helmet } from "react-helmet";
import AboutHome from "../components/home/AboutHome";
import HomeHero from "../components/home/HomeHero";
import GreatHabibResults from "../components/reusables/GreatHabibResults";

export default function Home() {
  return (
    <>
      <Helmet>
        <link
          rel="conanical"
          href="https://www.ghsfa.org/"
        />
      </Helmet>
      <HomeHero />
      <AboutHome />
      <GreatHabibResults />
    </>
  );
}
