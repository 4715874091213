import React from "react";
import GalleryCard from "../reusables/GalleryCard";
import bs1 from "../../assets/images/gallery/bs1.jpg";
import bs2 from "../../assets/images/gallery/bs2.jpg";
import bs3 from "../../assets/images/gallery/bs3.jpg";
import bs4 from "../../assets/images/gallery/bs4.jpg";
import bs5 from "../../assets/images/gallery/bs5.jpg";
import bs6 from "../../assets/images/gallery/bs6.jpg";
import bs7 from "../../assets/images/gallery/bs7.jpg";
import bs8 from "../../assets/images/gallery/bs8.jpg";
import cg1 from "../../assets/images/gallery/cg1.jpg";
import cg2 from "../../assets/images/gallery/cg2.jpg";
import cg3 from "../../assets/images/gallery/cg3.jpg";
import eg1 from "../../assets/images/gallery/eg1.jpg";
import eg2 from "../../assets/images/gallery/eg2.jpg";
import eg3 from "../../assets/images/gallery/eg3.jpg";
import eg4 from "../../assets/images/gallery/eg4.jpg";
import ir1 from "../../assets/images/gallery/ir1.jpg";
import ir2 from "../../assets/images/gallery/ir2.jpg";
import ir3 from "../../assets/images/gallery/ir3.jpg";
import jpk from "../../assets/images/gallery/jpk.jpg";
import ms1 from "../../assets/images/gallery/ms1.jpg";
import ms2 from "../../assets/images/gallery/ms2.jpg";
import pc1 from "../../assets/images/gallery/pc1.jpg";
import pc2 from "../../assets/images/gallery/pc2.jpg";
import pg from "../../assets/images/gallery/pg.jpg";
import pk1 from "../../assets/images/gallery/pk1.jpg";
import pk2 from "../../assets/images/gallery/pk2.jpg";
import pk3 from "../../assets/images/gallery/pk3.jpg";
import pk4 from "../../assets/images/gallery/pk4.jpg";
import pk5 from "../../assets/images/gallery/pk5.jpg";
import pk6 from "../../assets/images/gallery/pk6.jpg";
import pk7 from "../../assets/images/gallery/pk7.jpg";
import pk8 from "../../assets/images/gallery/pk8.jpg";
import pk9 from "../../assets/images/gallery/pk9.jpg";
import pk10 from "../../assets/images/gallery/pk10.jpg";
import pk11 from "../../assets/images/gallery/pk11.jpg";
import pk12 from "../../assets/images/gallery/pk12.jpg";
import rg1 from "../../assets/images/gallery/rg1.jpg";
import rg2 from "../../assets/images/gallery/rg2.jpg";
import rg3 from "../../assets/images/gallery/rg3.jpg";
import ro1 from "../../assets/images/gallery/ro1.jpg";
import ro2 from "../../assets/images/gallery/ro2.jpg";
import tk1 from "../../assets/images/gallery/tk1.jpg";
import tk2 from "../../assets/images/gallery/tk2.jpg";
import tk3 from "../../assets/images/gallery/tk3.jpg";

const GalleryBody = () => {
  return (
    <section className=" section-spacing-80 no-padding-top">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content white-bg box-shadow relative margin-auto border-radius-2">
              <div className="section-body">
                <div className="padding-16 center">
                  <div className="gallery-container">
                    <GalleryCard
                      galleryImage={bs1}
                      galleryImageCaption="Blue Sweddish Ducks"
                    />
                    <GalleryCard
                      galleryImage={bs2}
                      galleryImageCaption="Blue Sweddish Ducks"
                    />
                    <GalleryCard
                      galleryImage={bs3}
                      galleryImageCaption="Blue Sweddish Ducks"
                    />
                    <GalleryCard
                      galleryImage={bs4}
                      galleryImageCaption="Blue Sweddish Ducks"
                    />
                    <GalleryCard
                      galleryImage={bs5}
                      galleryImageCaption="Blue Sweddish Ducks"
                    />
                    <GalleryCard
                      galleryImage={bs6}
                      galleryImageCaption="Blue Sweddish Ducks"
                    />
                    <GalleryCard
                      galleryImage={bs7}
                      galleryImageCaption="Blue Sweddish Ducks"
                    />
                    <GalleryCard
                      galleryImage={bs8}
                      galleryImageCaption="Blue Sweddish Ducks"
                    />
                    <GalleryCard
                      galleryImage={cg1}
                      galleryImageCaption="Chineese Goose"
                    />
                    <GalleryCard
                      galleryImage={cg2}
                      galleryImageCaption="Chineese Goose"
                    />
                    <GalleryCard
                      galleryImage={cg3}
                      galleryImageCaption="Chineese Goose"
                    />
                    <GalleryCard
                      galleryImage={eg1}
                      galleryImageCaption="Eggs"
                    />
                    <GalleryCard
                      galleryImage={eg2}
                      galleryImageCaption="Eggs"
                    />
                    <GalleryCard
                      galleryImage={eg3}
                      galleryImageCaption="Eggs"
                    />
                    <GalleryCard
                      galleryImage={eg4}
                      galleryImageCaption="Eggs"
                    />
                    <GalleryCard
                      galleryImage={ir1}
                      galleryImageCaption="Indian Runners Goose"
                    />
                    <GalleryCard
                      galleryImage={ir2}
                      galleryImageCaption="Indian Runners Goose"
                    />
                    <GalleryCard
                      galleryImage={ir3}
                      galleryImageCaption="Indian Runners Goose"
                    />
                    <GalleryCard
                      galleryImage={ms1}
                      galleryImageCaption="Muscovy Ducks"
                    />
                    <GalleryCard
                      galleryImage={ms2}
                      galleryImageCaption="Muscovy Ducks"
                    />
                    <GalleryCard
                      galleryImage={pc1}
                      galleryImageCaption="Pigeon Coop"
                    />
                    <GalleryCard
                      galleryImage={pc2}
                      galleryImageCaption="Pigeon Coop"
                    />
                    <GalleryCard
                      galleryImage={pg}
                      galleryImageCaption="Pigeons"
                    />
                    <GalleryCard
                      galleryImage={jpk}
                      galleryImageCaption="Juvenile Pekins"
                    />
                    <GalleryCard
                      galleryImage={pk2}
                      galleryImageCaption="Pekins"
                    />
                    <GalleryCard
                      galleryImage={pk3}
                      galleryImageCaption="Pekins"
                    />
                    <GalleryCard
                      galleryImage={pk4}
                      galleryImageCaption="Pekins"
                    />
                    <GalleryCard
                      galleryImage={pk5}
                      galleryImageCaption="Pekins"
                    />
                    <GalleryCard
                      galleryImage={pk6}
                      galleryImageCaption="Pekins"
                    />
                    <GalleryCard
                      galleryImage={pk7}
                      galleryImageCaption="Pekins"
                    />
                    <GalleryCard
                      galleryImage={pk8}
                      galleryImageCaption="Pekins"
                    />
                    <GalleryCard
                      galleryImage={pk9}
                      galleryImageCaption="Pekins"
                    />
                    <GalleryCard
                      galleryImage={pk10}
                      galleryImageCaption="Pekins"
                    />
                    <GalleryCard
                      galleryImage={pk11}
                      galleryImageCaption="Pekins"
                    />
                    <GalleryCard
                      galleryImage={pk12}
                      galleryImageCaption="Pekins"
                    />
                    <GalleryCard
                      galleryImage={rg1}
                      galleryImageCaption="Roman Goose"
                    />
                    <GalleryCard
                      galleryImage={rg2}
                      galleryImageCaption="Roman Goose"
                    />{" "}
                    <GalleryCard
                      galleryImage={rg3}
                      galleryImageCaption="Roman Goose"
                    />
                    <GalleryCard
                      galleryImage={ro1}
                      galleryImageCaption="Rouen Ducks"
                    />
                    <GalleryCard
                      galleryImage={ro2}
                      galleryImageCaption="Rouen Ducks"
                    />
                    <GalleryCard
                      galleryImage={tk1}
                      galleryImageCaption="Turkeys"
                    />
                    <GalleryCard
                      galleryImage={tk2}
                      galleryImageCaption="Turkeys"
                    />
                    <GalleryCard
                      galleryImage={tk3}
                      galleryImageCaption="Turkeys"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GalleryBody;
