import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { settings1 } from "../reusables/carouselincard";

const PdtCardwithCarousel = (props) => {
  return (
    <div className="pdt-card">
      <div className="pdt-img-container slider-items-container center-slide-arrows">
        <Slider {...settings1}>
          <div className="pdt-img">
            <img src={props.pdtImg1} alt={props.pdtName1} />
            <span className="pname-span">{props.pdtName1}</span>
          </div>
          <div className="pdt-img">
            <img src={props.pdtImg2} alt={props.pdtName2} />
            <span className="pname-span">{props.pdtName2}</span>
          </div>
          <div className="pdt-img">
            <img src={props.pdtImg3} alt={props.pdtName3} />
            <span className="pname-span">{props.pdtName3}</span>
          </div>
          <div className="pdt-img">
            <img src={props.pdtImg4} alt={props.pdtName4} />
            <span className="pname-span">{props.pdtName4}</span>
          </div>
        </Slider>
      </div>
      <div className="pdt-name">
        <h2>{props.pdtName}</h2>
      </div>
      <div className="pdt-desc">
        <p>{props.pdtDesc}</p>
      </div>
      <div className="pdt-cta">
        <a
          href={props.pdtLink}
          className="button card-cta boldTitle"
          target="_blank"
          rel="noopener noreferrer"
        >
          Order Now
        </a>
      </div>
    </div>
  );
};

export default PdtCardwithCarousel;
