import React from "react";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";

const AcademyBody = () => {
  return (
    <section className="section section-spacing-50 farm-bg">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content white-bg width-960 box-shadow relative margin-auto border-radius-2">
              <div className="section-body minus-margin-top-100">
                <div className="farm-tour-video-container">
                  <LiteYouTubeEmbed
                    id="J3r4-TEI16A"
                    title="Great Habib Smart Farm Academy"
                  />
                </div>
              </div>
            </div>
            <div className="center section-spacing-50">
              <a
                href="https://form.jotform.com/greathabibfarmacademy/farm-visit-booking"
                target="_blank"
                rel="noopener noreferrer"
                className="button subcta-lightgreen"
              >
                Book Your Farm Visit
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AcademyBody;
