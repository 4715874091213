import React from "react";

const Servicecard = (props) => {
  return (
    <div className="service-card">
      <div className="service-card-head">
        <h2>{props.servTitle}</h2>
      </div>
      <div className="service-card-image">
        <img src={props.servImage} alt={props.servTitle} />
      </div>
      <div className="service-card-desc">{props.servDesc}</div>
      <div className="service-card-cta">
        <a
          href={props.servLink}
          className="button card-cta boldTitle"
          target="_blank"
          rel="noopener noreferrer"
        >
          {props.servLinkCTA}
        </a>
      </div>
    </div>
  );
};

export default Servicecard;
