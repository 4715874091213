import React from "react";

const PdtCard = (props) => {
  return (
    <div className="pdt-card">
      <div className="pdt-img">
        <img src={props.pdtImg} alt={props.pdtName} />
      </div>
      <div className="pdt-name">
        <h2>{props.pdtName}</h2>
      </div>
      <div className="pdt-desc">
        <p>{props.pdtDesc}</p>
      </div>
      <div className="pdt-cta">
        <a
          href={props.pdtLink}
          className="button card-cta boldTitle"
          target="_blank"
          rel="noopener noreferrer"
        >
          Order Now
        </a>
      </div>
    </div>
  );
};

export default PdtCard;
