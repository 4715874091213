import React from "react";
import { Slide } from "react-reveal";
import smartdata from "../../assets/images/content/smartfarm4.jpg";
import big from "../../assets/images/gallery/pk8.jpg";

const AboutHome = () => {
  return (
    <section className="section section-spacing-180 farm-bg">
      <div className="container">
        <div className="large">
          <div className="section-content">
            <div className="about-contents relative flex">
              <div className="about-text-side">
                <Slide top>
                  <div className="section-head">
                    <h2>
                      <span className="light-green">Introducing</span> GHSFA
                    </h2>
                  </div>
                </Slide>
                <Slide left>
                  <div className="section-body">
                    <h3 className="about-snippet">
                      <span className="dark-yellow">Revolutionize</span>{" "}
                      agriculture,
                      <span className="light-green">Shape the future</span> of
                      farming
                    </h3>
                    <p>
                      In the heart of East Africa, where the lush landscapes of
                      Uganda unfold, a beacon of innovation is emerging to
                      revolutionize agriculture and shape the future of farming.
                      The Great Habib Smart Farm Academy stands as a testament
                      to the transformative power of digital technologies in
                      driving change across the agricultural landscape of Uganda
                      and beyond.
                    </p>
                    {/*
                    <p>
                      Nestled amid the fertile fields and vibrant communities of
                      East Africa, the Great Habib Smart Farm Academy is not
                      just an institution; it's a visionary hub committed to
                      redefining agriculture through digital advancements. The
                      academy aims to equip farmers with cutting-edge
                      technologies, modern farming practices, and data-driven
                      solutions to propel the region towards sustainable and
                      efficient agricultural practices.
                    </p>
                    */}
                  </div>
                </Slide>

                <div className="section-footer buttons-container section-spacing-20 no-padding-bottom">
                  <a
                    href="/about-us"
                    className="button boldTitle subcta-green"
                  >
                    About Us
                  </a>
                  <a
                    href="/academy"
                    className="button boldTitle subcta-lightgreen"
                  >
                    Tour Farm
                  </a>
                </div>
              </div>
              <Slide right>
                <div className="about-image-side">
                  <img src={big} className="box-shadow" alt="Image" />
                  <img
                    src={smartdata}
                    className="box-shadow floating-image"
                    alt="Image"
                  />
                </div>
              </Slide>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutHome;
