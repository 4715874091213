import React from "react";
import { Helmet } from "react-helmet";
import InitiativesBody from "../components/initiatives/InitiativesBody";
import GreatHabibResults from "../components/reusables/GreatHabibResults";
import PageHero from "../components/reusables/hero/PageHero";

export default function Initiatives() {
  return (
    <>
      <Helmet>
        <link
          rel="conanical"
          href="https://www.ghsfa.org/initiatives"
        />
        <meta
          name="description"
          content="The Smart Farm Academy Digitally Driving Agricultural Transformation in Uganda and East Africa"
        />
        <meta
          property="og:description"
          content="The Smart Farm Academy Digitally Driving Agricultural Transformation in Uganda and East Africa"
        />
        <meta
          property="og:title"
          content="Key Initiatives - Great Habib Smart Farm Academy"
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1699450290/GREAT_HABIB_SMART_FARM_LOGO_izsiaz.jpg"
        />

        <meta
          name="twitter:title"
          content="Key Initiatives - Great Habib Smart Farm Academy"
        />
        <meta
          name="twitter:text:title"
          content="Key Initiatives - Great Habib Smart Farm Academy"
        />
        <meta
          name="twitter:description"
          content="The Smart Farm Academy Digitally Driving Agricultural Transformation in Uganda and East Africa"
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1699450290/GREAT_HABIB_SMART_FARM_LOGO_izsiaz.jpg"
        />
        <title>Key Initiatives - Great Habib Smart Farm Academy</title>
      </Helmet>
      <PageHero
        classes="ducks-bg"
        titleClasses="title uppercase yellow"
        title="GHSFA's Key Initiatives"
      />
      <InitiativesBody />
      <GreatHabibResults />
    </>
  );
}
