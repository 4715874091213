import React from "react";
import smartdata from "../../assets/images/background/ducks1.jpg";
import Servicecard from "../reusables/ServiceCard";

const ServicesBody = () => {
  return (
    <section className="section section-spacing-100">
      <div className="container">
        <div className="large">
          <div className="section-content">
            <div className="services-container">
              <Servicecard
                servTitle="Farm Visitings"
                servLink="https://form.jotform.com/greathabibfarmacademy/farm-visit-booking"
                servLinkCTA="Book Your Visit"
                servImage={smartdata}
                servDesc="We are a model farm"
              />
              <Servicecard
                servTitle="Farming Training"
                servLink="https://form.jotform.com/greathabibfarmacademy/enrollment"
                servLinkCTA="Enroll Now"
                servImage={smartdata}
                servDesc="Learn from the best"
              />
              <Servicecard
                servTitle="Selling Farm Produce"
                servLink="/shop"
                servLinkCTA="Farm Shop"
                servImage={smartdata}
                servDesc="Shop organic farm products"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesBody;
