import React from "react";
import { Slide } from "react-reveal";

const GreatHabibResults = () => {
  return (
    <section className="section section-spacing-50 results">
      <div className="container">
        <div className="large">
          <div className="section-content">
            <div className="about-results-container">
              <h4 className=" results-title width-800 margin-auto">
                We intend to create lasting positive change in Uganda's
                agricultural landscape by leveraging the power of digital
                technologies and innovative farming practices.
              </h4>
              <div className="about-results-inner flex">
                <Slide left>
                  <div className="about-results-item">
                    <h5>50000 +</h5>
                    <p>99% Organically Fed Ducks</p>
                  </div>
                </Slide>
                <Slide top>
                  <div className="about-results-item">
                    <h5>10 +</h5>
                    <p>Breeds of Ducks</p>
                  </div>
                </Slide>
                <Slide right>
                  <div className="about-results-item">
                    <h5>10 +</h5>
                    <p>Value Added Farm Products</p>
                  </div>
                </Slide>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GreatHabibResults;
