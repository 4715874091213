import React from "react";
import Mission from "../reusables/Mission";
import smartdata from "../../assets/images/logo/logo.png";

const InitiativesBody = () => {
  return (
    <section className="section section-spacing-50 no-padding-bottom">
      <div className="container">
        <div className="large">
          <div className="section-content">
            <div className="textpage-container">
              <div className="textpage-inner section-spacing-50 no-padding-top flex">
                <div className="textpage-text-side">
                  <div className="initiatives-container">
                    <div className="initiative-card">
                      <div className="card-head">
                        <h2>1. Digital Literacy Programs</h2>
                      </div>
                      <div className="card-body flex">
                        <div className="card-body-text">
                          <p>
                            The academy understands the critical role of digital
                            literacy in modern agriculture. It conducts
                            comprehensive programs to educate farmers on the use
                            of smartphones, tablets, and other digital devices.
                            Farmers are trained to access and interpret
                            agricultural data, weather forecasts, market trends,
                            and best practices, fostering a culture of
                            continuous learning.
                          </p>
                          <div className="card-footer margin-top-20">
                            <a
                              href="#"
                              className="button boldTitle subcta-yellow"
                            >
                              Read More
                            </a>
                          </div>
                        </div>
                        <div className="card-image-side">
                          <img
                            src={smartdata}
                            className="box-shadow"
                            alt="Image"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="initiative-card ordered">
                      <div className="card-head">
                        <h2>2. Precision Farming Techniques</h2>
                      </div>
                      <div className="card-body flex">
                        <div className="card-body-text">
                          <p>
                            Embracing precision farming, the academy introduces
                            farmers to advanced techniques such as satellite
                            imaging, GPS-guided tractors, and sensor-based
                            monitoring. By optimizing resource use, reducing
                            waste, and improving efficiency, farmers can enhance
                            productivity while minimizing environmental impact.
                          </p>
                          <div className="card-footer margin-top-20">
                            <a
                              href="#"
                              className="button boldTitle subcta-yellow"
                            >
                              Read More
                            </a>
                          </div>
                        </div>
                        <div className="card-image-side">
                          <img
                            src={smartdata}
                            className="box-shadow"
                            alt="Image"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="initiative-card">
                      <div className="card-head">
                        <h2>3. AgriTech Workshops and Incubation</h2>
                      </div>
                      <div className="card-body flex">
                        <div className="card-body-text">
                          <p>
                            Great Habib Smart Farm Academy serves as an
                            incubation hub for AgriTech startups. It hosts
                            workshops, hackathons, and innovation challenges to
                            encourage the development of digital solutions
                            tailored to the unique needs of East African
                            agriculture. The academy actively collaborates with
                            technology developers to bridge the gap between
                            traditional farming and cutting-edge innovations.
                          </p>
                          <div className="card-footer margin-top-20">
                            <a
                              href="#"
                              className="button boldTitle subcta-yellow"
                            >
                              Read More
                            </a>
                          </div>
                        </div>
                        <div className="card-image-side">
                          <img
                            src={smartdata}
                            className="box-shadow"
                            alt="Image"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="initiative-card ordered">
                      <div className="card-head">
                        <h2>4. Data Analytics and Farm Management</h2>
                      </div>
                      <div className="card-body flex">
                        <div className="card-body-text">
                          <p>
                            The academy emphasizes the role of data analytics in
                            farm management. Farmers are trained to collect,
                            analyze, and interpret data related to soil health,
                            crop cycles, and market trends. Through intuitive
                            software applications, they can make informed
                            decisions to optimize crop yields and maximize
                            profitability.
                          </p>
                          <div className="card-footer margin-top-20">
                            <a
                              href="#"
                              className="button boldTitle subcta-yellow"
                            >
                              Read More
                            </a>
                          </div>
                        </div>
                        <div className="card-image-side">
                          <img
                            src={smartdata}
                            className="box-shadow"
                            alt="Image"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="initiative-card">
                      <div className="card-head">
                        <h2>5. Community Engagement and Outreach</h2>
                      </div>
                      <div className="card-body flex">
                        <div className="card-body-text">
                          <p>
                            Recognizing the importance of community involvement,
                            the academy actively engages with local farmers,
                            cooperatives, and agricultural organizations.
                            Outreach programs include field demonstrations,
                            community workshops, and collaborative projects to
                            create a network of digitally literate and empowered
                            farmers.
                          </p>
                          <div className="card-footer margin-top-20">
                            <a
                              href="#"
                              className="button boldTitle subcta-yellow"
                            >
                              Read More
                            </a>
                          </div>
                        </div>
                        <div className="card-image-side">
                          <img
                            src={smartdata}
                            className="box-shadow"
                            alt="Image"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="initiative-card ordered">
                      <div className="card-head">
                        <h2>6. Impact and Future Prospects</h2>
                      </div>
                      <div className="card-body flex">
                        <div className="card-body-text">
                          <p>
                            As the Great Habib Smart Farm Academy gains
                            momentum, the impact on East African agriculture
                            becomes increasingly evident. Farmers equipped with
                            digital skills and modern farming techniques
                            experience higher yields, reduced environmental
                            impact, and increased profitability. The academy's
                            success becomes a catalyst for a broader digital
                            transformation across the region, attracting
                            attention and support from governments, NGOs, and
                            international partners.
                          </p>
                          <div className="card-footer margin-top-20">
                            <a
                              href="#"
                              className="button boldTitle subcta-yellow"
                            >
                              Read More
                            </a>
                          </div>
                        </div>
                        <div className="card-image-side">
                          <img
                            src={smartdata}
                            className="box-shadow"
                            alt="Image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Mission />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InitiativesBody;
