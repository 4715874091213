import React from "react";
import PdtCard from "../reusables/PdtCard";
import PdtCardwithCarousel from "../reusables/PdtCardwithCarousel";
import smartdata from "../../assets/images/farm/eggs.jpg";
import muscovy from "../../assets/images/farm/muscovy.jpg";
import rouen from "../../assets/images/farm/rouenduck.jpg";
import rouen1 from "../../assets/images/farm/rouen.jpg";
import blueswedish from "../../assets/images/farm/blueswedish.jpg";
import chinesegoose from "../../assets/images/farm/chinesegoose.jpg";
import indianrunners from "../../assets/images/farm/indianrunners.jpg";
import pekins from "../../assets/images/farm/pekins.jpg";
import turk from "../../assets/images/farm/turkey.jpg";
import turk1 from "../../assets/images/farm/turkey1.jpg";
import turk2 from "../../assets/images/farm/turkey2.jpg";
import romangoose from "../../assets/images/farm/romangoose.jpg";
import eggs from "../../assets/images/farm/eggs3.jpg";
import eggs1 from "../../assets/images/farm/eggs.jpg";
import eggs2 from "../../assets/images/farm/eggs.jpg";
import eggs3 from "../../assets/images/farm/eggs1.jpg";
import seed from "../../assets/images/farm/seeds.jpg";
import seed1 from "../../assets/images/farm/seeds1.jpg";
import seed2 from "../../assets/images/farm/seeds2.webp";
import seed3 from "../../assets/images/farm/seeds3.jpg";
import seedling3 from "../../assets/images/farm/seedling3.webp";
import seedling2 from "../../assets/images/farm/seedling.jpg";
import seedling1 from "../../assets/images/farm/seedling2.webp";
import pellet from "../../assets/images/farm/pellet.jpg";
import pellet1 from "../../assets/images/farm/pellet2.jpg";
import pellet2 from "../../assets/images/farm/pellet3.jpg";
import irrig from "../../assets/images/farm/solarirrig1.webp";

const ShopBody = () => {
  return (
    <section className="section section-spacing-100">
      <div className="container">
        <div className="large">
          <div className="section-content width-960 margin-auto">
            <div className="pdts-cards-container flex">
              <PdtCardwithCarousel
                pdtImg1={muscovy}
                pdtImg2={rouen}
                pdtImg3={blueswedish}
                pdtImg4={rouen1}
                pdtName1="Muscovy Ducks"
                pdtName2="Rouen Ducks"
                pdtName3="Blue Swedish Ducks"
                pdtName4="Rouen Ducks"
                pdtName="Ducks"
                pdtDesc="Muscovy, Rouen, Blue Swedish"
                pdtLink="https://form.jotform.com/greathabibfarmacademy/order-form"
              />
              <PdtCardwithCarousel
                pdtImg1={chinesegoose}
                pdtImg2={indianrunners}
                pdtImg3={romangoose}
                pdtImg4={pekins}
                pdtName1="Chineese Goose"
                pdtName2="Indian Runners"
                pdtName3="Roman Goose"
                pdtName4="Pekins"
                pdtName="Goose"
                pdtDesc="Pekins, Roman, Indian Runners, Chineese"
                pdtLink="https://form.jotform.com/greathabibfarmacademy/order-form"
              />
              <PdtCardwithCarousel
                pdtImg1={turk}
                pdtImg2={turk1}
                pdtImg3={turk2}
                pdtImg4={turk}
                pdtName1="White Turkey"
                pdtName2="Black Turkey"
                pdtName3="Black Turkey"
                pdtName4="White Turkey"
                pdtName="Turkey"
                pdtDesc="Juvenile &amp; Matured"
                pdtLink="https://form.jotform.com/greathabibfarmacademy/order-form"
              />
              <PdtCardwithCarousel
                pdtImg1={eggs}
                pdtImg2={eggs1}
                pdtImg3={eggs2}
                pdtImg4={eggs3}
                pdtName1="Eggs"
                pdtName2="Eggs"
                pdtName3="Eggs"
                pdtName4="Eggs"
                pdtName="Eggs"
                pdtDesc="Eggs of all birds we keep"
                pdtLink="https://form.jotform.com/greathabibfarmacademy/order-form"
              />
              <PdtCardwithCarousel
                pdtImg1={seed}
                pdtImg2={seed1}
                pdtImg3={seed2}
                pdtImg4={seed3}
                pdtName1="Beans"
                pdtName2="Soya"
                pdtName3="Beans"
                pdtName4="Maoze"
                pdtName="Seeds"
                pdtDesc="Beans, Maize, Soya"
                pdtLink="https://form.jotform.com/greathabibfarmacademy/order-form"
              />
              <PdtCardwithCarousel
                pdtImg1={seedling3}
                pdtImg2={seedling2}
                pdtImg3={seedling3}
                pdtImg4={seedling1}
                pdtName1="Mango"
                pdtName2="Avocado"
                pdtName3="Mango"
                pdtName4="Citrus"
                pdtName="Seedlings"
                pdtDesc="Avocado, Citrus, Mango"
                pdtLink="https://form.jotform.com/greathabibfarmacademy/order-form"
              />
              <PdtCardwithCarousel
                pdtImg1={pellet}
                pdtImg2={pellet1}
                pdtImg3={pellet2}
                pdtImg4={pellet1}
                pdtName1="PMM"
                pdtName2="PMM"
                pdtName3="PMM"
                pdtName4="PMM"
                pdtName="Pellet Making Machines"
                pdtDesc="Make All Pellets"
                pdtLink="https://form.jotform.com/greathabibfarmacademy/order-form"
              />
              <PdtCard
                pdtImg={irrig}
                pdtName="Sollar Irrigation Systems"
                pdtDesc="Includes Training"
                pdtLink="https://form.jotform.com/greathabibfarmacademy/order-form"
              />
              <PdtCard
                pdtImg={irrig}
                pdtName="Sollar Lights &amp; Bulbs"
                pdtDesc="Power Saving"
                pdtLink="https://form.jotform.com/greathabibfarmacademy/order-form"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShopBody;
