import React from "react";
import logo from "../../assets/images/logo/logo.png";

const Footer = () => {
  return (
    <section className="footer section-spacing-20 no-padding-bottom dark-bg">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content section-spacing-50">
              <div className="footer-menu flex">
                <div className="footer-menu-item about-footer">
                  <img src={logo} className=" box-shadow" alt="Logo" />
                  <div className="footer-body">
                    <p>
                      The Great Habib Smart Farm Academy is not just an
                      institution; it's a visionary hub committed to redefining
                      agriculture through digital advancements. The academy aims
                      to equip farmers with cutting-edge technologies, modern
                      farming practices, and data-driven solutions to propel the
                      region towards sustainable and efficient agricultural
                      practices.
                    </p>
                  </div>
                  <div className="footer-subscribe section-spacing-20">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="button subcta-lightgreen width-100 boldTitle"
                      href="https://form.jotform.com/greathabibfarmacademy/subscribe-form"
                    >
                      Subscribe To Our Newsletter
                    </a>
                  </div>
                </div>
                <div className="footer-menu-item">
                  <h3 className="footer-menu-title">Quick Links</h3>
                  <ul className="footer-nav">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/about-us">About Us</a>
                    </li>
                    <li>
                      <a href="/team">Our Team</a>
                    </li>
                    <li>
                      <a href="#">Our partners</a>
                    </li>
                    <li>
                      <a href="/initiatives">Key Initiatives</a>
                    </li>
                  </ul>
                </div>
                <div className="footer-menu-item">
                  <h3 className="footer-menu-title">Academy</h3>
                  <ul className="footer-nav">
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://form.jotform.com/greathabibfarmacademy/enrollment"
                      >
                        Enroll
                      </a>
                    </li>
                    <li>
                      <a href="/gallery">Farm Tour</a>
                    </li>
                    <li>
                      <a href="/shop">Farm Shop</a>
                    </li>
                    <li>
                      <a href="/contact-us">Contact Us</a>
                    </li>
                    <li>
                      <a href="/services">Our Services</a>
                    </li>
                  </ul>
                </div>
                <div className="footer-menu-item">
                  <h3 className="footer-menu-title">Social Media</h3>
                  <ul className="footer-nav">
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://twitter.com/ghsfacademy"
                      >
                        Twitter
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/company/great-habib-smart-academy/"
                      >
                        Linkedin
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/ghsfacademy"
                      >
                        Facebook
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.instagram.com/ghsfacademy/"
                      >
                        Instagram
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://wa.me/256756610041"
                      >
                        WhatsApp
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="copyright padding-top-20 padding-bottom-8 center ntuna-yellow">
              <p>
                &copy; 2023 GREAT HABIB SMART FARM ACADEMY, All Rights Reserved
              </p>
            </div>
            <div className="designer section-spacing-10 uppercase">
              <div className="design-inner white center">
                <p className=" rich-small">
                  Website by{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://ntuna.com"
                    className="light-green"
                  >
                    NTUNA.COM
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
