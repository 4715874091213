import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import PageNotFound404 from "./pages/PageNotFound404";
import About from "./pages/About";
import Initiatives from "./pages/Initiatives";
import Services from "./pages/Services";
import Shop from "./pages/Shop";
import Contact from "./pages/Contact";
import Gallery from "./pages/Gallery";
import Academy from "./pages/Academy";
import Team from "./pages/Team";
import FloatingButton from "./floatingbutton/FloatingButton";

export default class App extends React.PureComponent {
  render() {
    return (
      <Router>
        <div className="app" id="page">
          <a href="#content" className="skip-to-content-link">
            Skip to Content
          </a>
          <Header />
          <FloatingButton />
          <div className="site-content" id="content">
            <Switch>
              <Route exact path="/home">
                <Home />
              </Route>
              <Route exact path="/about-us">
                <About />
              </Route>
              <Route exact path="/initiatives">
                <Initiatives />
              </Route>
              <Route exact path="/services">
                <Services />
              </Route>
              <Route exact path="/shop">
                <Shop />
              </Route>
              <Route exact path="/academy">
                <Academy />
              </Route>
              <Route exact path="/team">
                <Team />
              </Route>
              <Route exact path="/contact-us">
                <Contact />
              </Route>
              <Route exact path="/gallery">
                <Gallery />
              </Route>
              <Route exact path="/404">
                <PageNotFound404 />
              </Route>

              <Route path="*">
                <Redirect to="/home" />
              </Route>
            </Switch>
          </div>

          <Footer />
        </div>
      </Router>
    );
  }
}
