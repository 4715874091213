import React from "react";
import Slider from "react-slick";
import { Slide } from "react-reveal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { settings } from "../reusables/carousel";
import smart from "../../assets/images/farm/ducks1.jpg";
import visit from "../../assets/images/farm/visit.jpg";
import muscovy from "../../assets/images/farm/muscovy.jpg";
import pekins from "../../assets/images/farm/pekins.jpg";
import pigeons from "../../assets/images/farm/pigeons.jpg";
import turkey from "../../assets/images/farm/turkey1.jpg";
import eggs from "../../assets/images/farm/eggs.jpg";
import CarCard from "../reusables/CarCard";

const HomeHero = () => {
  return (
    <section className="hero">
      <div className="carous">
        <div className="slider-items-container center-slide-arrows">
          <Slider {...settings}>
            <CarCard bgImg={smart}>
              <Slide top>
                <div className="hero-content-container welcome">
                  <div className="hero-content-inner centered">
                    <div className="hero-body centered-inner">
                      <div className="hero-body-head">
                        <h1 className=" margin-bottom-20">
                          <span className="block">Welcome to</span> Great Habib Smart Farm Academy
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
            </CarCard>
            <CarCard bgImg={visit}>
              <Slide left>
                <div className="hero-content-container">
                  <div className="hero-content-inner centered">
                    <div className="hero-body centered-inner">
                      <div className="hero-body-head">
                        <h3 className=" margin-bottom-20">Our Services</h3>
                      </div>
                      <div className="hero-body-body">
                        <p className="gray">
                          We train farmers, allow farms visits and sell farm
                          proceeds.
                        </p>
                      </div>
                      <div className="hero-body-footer margin-top-20">
                        <a
                          href="/services"
                          className="button subcta boldTitle uppercase"
                        >
                          See More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
            </CarCard>
            <CarCard bgImg={muscovy}>
              <Slide right>
                <div className="hero-content-container">
                  <div className="hero-content-inner centered">
                    <div className="hero-body centered-inner">
                      <div className="hero-body-head">
                        <h2 className=" margin-bottom-20">What We Sell</h2>
                      </div>
                      <div className="hero-body-body">
                        <h3>Ducks</h3>
                        <p className="gray">Muscovy, Blue Swedish, Rouen</p>
                      </div>
                      <div className="hero-body-footer margin-top-20">
                        <a
                          href="#"
                          className="button subcta boldTitle uppercase"
                        >
                          Farm Shop
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
            </CarCard>
            <CarCard bgImg={pekins}>
              <Slide bottom>
                <div className="hero-content-container">
                  <div className="hero-content-inner centered">
                    <div className="hero-body centered-inner">
                      <div className="hero-body-head">
                        <h2 className=" margin-bottom-20">What We Sell</h2>
                      </div>
                      <div className="hero-body-body">
                        <h3>Goose</h3>
                        <p className="gray">
                          Pekins, Roman Goose, Indian Runners, Chineese Goose
                        </p>
                      </div>
                      <div className="hero-body-footer margin-top-20">
                        <a
                          href="/shop"
                          className="button subcta boldTitle uppercase"
                        >
                          Farm Shop
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
            </CarCard>
            <CarCard bgImg={turkey}>
              <Slide top>
                <div className="hero-content-container">
                  <div className="hero-content-inner centered">
                    <div className="hero-body centered-inner">
                      <div className="hero-body-head">
                        <h2 className=" margin-bottom-20">What We Sell</h2>
                      </div>
                      <div className="hero-body-body">
                        <h3>Turkeys</h3>
                        <p className="gray">Juvenile and Old Turkeys</p>
                      </div>
                      <div className="hero-body-footer margin-top-20">
                        <a
                          href="/shop"
                          className="button subcta boldTitle uppercase"
                        >
                          Farm Shop
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
            </CarCard>
            <CarCard bgImg={pigeons}>
              <Slide right>
                <div className="hero-content-container">
                  <div className="hero-content-inner centered">
                    <div className="hero-body centered-inner">
                      <div className="hero-body-head">
                        <h2 className=" margin-bottom-20">What We Sell</h2>
                      </div>
                      <div className="hero-body-body">
                        <h3>Piegons</h3>
                      </div>
                      <div className="hero-body-footer margin-top-20">
                        <a
                          href="/shop"
                          className="button subcta boldTitle uppercase"
                        >
                          Farm Shop
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
            </CarCard>

            <CarCard bgImg={eggs}>
              <Slide left>
                <div className="hero-content-container">
                  <div className="hero-content-inner centered">
                    <div className="hero-body centered-inner">
                      <div className="hero-body-head">
                        <h2 className=" margin-bottom-20">What We Sell</h2>
                      </div>
                      <div className="hero-body-body">
                        <h3>Eggs</h3>
                        <p className="gray">
                          Goose eggs, Ducks eggs, Pigeon eggs, Turkey eggs
                        </p>
                      </div>
                      <div className="hero-body-footer margin-top-20">
                        <a
                          href="/shop"
                          className="button subcta boldTitle uppercase"
                        >
                          Farm Shop
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
            </CarCard>
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default HomeHero;
