import React from "react";
import { Slide } from "react-reveal";
import bull from "../../assets/images/icons/bull.svg";
import eye from "../../assets/images/icons/eye.svg";
import Mission from "../reusables/Mission";

const AboutBody = () => {
  return (
    <section className="section section-spacing-50 no-padding-bottom farm-bg">
      <div className="container">
        <div className="large">
          <div className="section-content">
            <div className="textpage-container">
              <div className="textpage-inner section-spacing-50 no-padding-top flex">
                <div className="textpage-text-side">
                  <h2 className=" light-green">
                    <span>Background</span> &amp; Inspiration
                  </h2>
                  <div className="textpage-text-body">
                    <p>
                      We are Great Habib Smart Farm Academy. We are Digitally
                      Driving Agricultural Transformation in Uganda and East
                      Africa.
                    </p>
                    <h3>Historical Agricultural Challenges in Uganda</h3>
                    <p>
                      Uganda, like many countries in East Africa, has faced
                      significant agricultural challenges over the years. These
                      challenges include unpredictable weather patterns,
                      inadequate access to modern farming technologies,
                      insufficient training for farmers, and a lack of
                      information on market trends.
                    </p>
                    <p>
                      Traditional farming practices, while deeply ingrained in
                      the culture, often struggled to meet the demands of a
                      growing population and a changing climate.
                    </p>
                    <h3>Visionary Leadership</h3>
                    <p>
                      The initiation of the Great Habib Smart Farm Academy can
                      often be attributed to visionary leaders who recognized
                      the potential for transformative change through the
                      integration of technology into agriculture.
                    </p>
                    <p>
                      These leaders, possibly driven by a deep commitment to
                      community development, saw an opportunity to uplift the
                      lives of farmers by equipping them with the tools and
                      knowledge needed for sustainable, efficient, and modern
                      farming practices.
                    </p>
                    <h3>Rising Importance of Digital Agriculture</h3>
                    <p>
                      As the global landscape shifted towards a digital era, the
                      founders likely recognized the rising importance of
                      digital agriculture.
                    </p>
                    <p>
                      The use of technologies such as precision farming, data
                      analytics, and AgriTech solutions became essential for
                      overcoming traditional farming challenges.
                    </p>
                    <p>
                      This realization likely served as a driving force behind
                      the establishment of the Great Habib Smart Farm Academy.
                    </p>
                    <h3>Empowering Local Communities</h3>
                    <p>
                      The founders might have been inspired by a desire to
                      empower local communities. Recognizing the potential of
                      farmers to become agents of change within their
                      communities, the academy could have been envisioned as a
                      catalyst for not only improving agricultural practices but
                      also enhancing the overall socio-economic conditions of
                      the region.
                    </p>
                    <h3>International Support and Collaboration</h3>
                    <p>
                      It's also plausible that international collaborations and
                      support played a role. The academy may have been initiated
                      in partnership with global organizations, governmental
                      bodies, or philanthropic entities that recognized the
                      importance of uplifting agricultural practices in Uganda.
                    </p>
                    <p>
                      Such collaborations could have provided the necessary
                      resources, expertise, and global best practices to make
                      the academy a success.
                    </p>
                    <h3>Environmental Sustainability and Food Security</h3>
                    <p>
                      Given the global emphasis on environmental sustainability
                      and food security, the founders might have seen an urgent
                      need to modernize agriculture to ensure long-term food
                      production while minimizing the environmental impact.
                    </p>
                    <p>
                      The academy could have been positioned as a key player in
                      fostering sustainable farming practices in the region.
                    </p>
                  </div>
                  <div className="textpage-text-model">
                    <p>
                      In the heart of East Africa, where the lush landscapes of
                      Uganda unfold, a beacon of innovation is emerging to
                      revolutionize agriculture and shape the future of farming.
                      The Great Habib Smart Farm Academy stands as a testament
                      to the transformative power of digital technologies in
                      driving change across the agricultural landscape of Uganda
                      and beyond.
                    </p>
                  </div>
                </div>
                <Mission />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutBody;
